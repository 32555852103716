// import VConsole from 'vconsole'

// const vConsole = new VConsole()
// or init with options
// const vConsole = new VConsole({ theme: 'dark' })

// call `console` methods as usual
// console.log('Hello world')

import Vue from 'vue'
var VUE_APP_BASE_IP = process.env.VUE_APP_BASE_IP
Vue.prototype.VUE_APP_BASE_IP = VUE_APP_BASE_IP
Vue.prototype.VUE_APP_BASE_API = localStorage.GMD_VUE_APP_BASE_API === undefined ? '//' + VUE_APP_BASE_IP + ':10004' : localStorage.GMD_VUE_APP_BASE_API // || '//' + VUE_APP_BASE_IP + ':10004'
if (Vue.prototype.VUE_APP_BASE_API.startsWith('//')) {
  Vue.prototype.VUE_APP_BASE_API = location.protocol + Vue.prototype.VUE_APP_BASE_API
}
Vue.prototype.VUE_APP_BASE_SOCKET = localStorage.GMD_VUE_APP_BASE_SOCKET === undefined ? '//' + VUE_APP_BASE_IP + ':8089' : localStorage.GMD_VUE_APP_BASE_SOCKET // || '//' + VUE_APP_BASE_IP + ':8089'
if (Vue.prototype.VUE_APP_BASE_SOCKET.startsWith('//')) {
  if (location.protocol === 'https:') {
    Vue.prototype.VUE_APP_BASE_SOCKET = 'wss:' + Vue.prototype.VUE_APP_BASE_SOCKET + '/ws'
  } else {
    Vue.prototype.VUE_APP_BASE_SOCKET = 'ws:' + Vue.prototype.VUE_APP_BASE_SOCKET + '/ws'
  }
}

import './login.persistence'
import * as constant from '@/constant'
import { LoginPage } from '@/constant'
Vue.prototype.$const = constant
Vue.prototype.$public = function (...args) {
  const eventName = args[0]
  // window.console.debug('事件名称：' + eventName)
  $app.$emit.apply($app, args)
}

Vue.prototype.gcj02towgs84 = gcj02towgs84

import App from './App.vue'
import router from './router'
import store from './store'

import * as lodash from 'lodash'

import { removeToken, setToken, getToken } from '@/utils/auth'

import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import './icons'
import '@/permission'

import * as echarts from 'echarts'
import ECharts from 'vue-echarts'

import '@/assets/styles/base.scss'
import '@/assets/styles/common.scss'
import '@/assets/iconfont/iconfont.css'

import Pagination from '@/components/pagination'
import Service from '@/utils/service.js'
import Storage from '@/utils/storage.js'
import ElementUI from 'element-ui'
import { MessageBox } from 'element-ui'
import Ui from '@/components/ui'



Vue.use(ElementUI)
Vue.use(Ui)
Vue.use(Service)
Vue.component('Pagination', Pagination)
Vue.use(Storage)
Vue.component('v-chart', ECharts)
Vue.config.productionTip = false
Vue.prototype.MessageBox = MessageBox
Vue.prototype.$socket = null
Vue.prototype.$instance = {}
Vue.prototype.$echarts = echarts
Vue.prototype.$setToken = setToken
Vue.prototype.$copy = function (obj) {
  return JSON.parse(JSON.stringify(obj))
}
Vue.prototype.$sum = function (arr) {
  return arr.reduce(function (prev, curr, idx, arr) {
    return prev + curr
  })
}
Vue.prototype.isArray = function (obj) {
  return Array.isArray(obj)
}
Vue.prototype.Math = Math
Vue.prototype._ = lodash
Vue.prototype.$wait = function (time = 0, resolver) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(resolver)
    }, time)
  })
}
Vue.prototype.$randomSortArr = function (arr) {
  var len = arr.length
  for (let i = len - 1; i >= 0; i--) {
    var randomIndex = Math.floor(Math.random() * (i + 1))
    var temp = arr[randomIndex]
    arr[randomIndex] = arr[i]
    arr[i] = temp
  }
  return arr
}

Object.defineProperty(Vue.prototype, 'userId', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.userId + '' : ''
  },
})

Object.defineProperty(Vue.prototype, 'uid', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.userId + '' : ''
  },
})

Object.defineProperty(Vue.prototype, '$videoEle', {
  get: function () {
    return window.$app ? window.$app.$children[0].$refs.video : null
  },
})

Object.defineProperty(Vue.prototype, 'companyInfo', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo.companyInfo : {}
  },
})

Object.defineProperty(Vue.prototype, 'userInfo', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.userInfo : {}
  },
})

Object.defineProperty(Vue.prototype, 'usersMap', {
  get: function () {
    return window.$app ? window.$app.$store.state.user.map : {}
  },
})
Vue.prototype.$console = function () {
  // window.console.debug(arguments)
  return true
}

Vue.prototype.loginInvalid = function (from) {
  if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
    window.$loginPersistenceUtils.tokenClear().then(() => {
      window.$loginPersistenceUtils.toLogin().then(() => {
        location.reload()
      })
    })
    return
  }
  window.console.debug('loginInvalid from', from, this.$route.name)
  if ($app.$store.state.isLogingOut) {
    return
  } else if (this.$route.name === LoginPage) {
    return
  }
  $app.$store.state.isLogingOut = true
  $app.MessageBox.alert('登入已失效，是否重新登录', {
    confirmButtonText: '重新登录',
    type: 'warning',
    showClose: false,
    closeOnClickModal: false,
  })
    .then(() => {
      this.logout()
    })
    .catch(() => {
      this.logout()
    })
}
Vue.prototype.$libs = {
  removeToken: removeToken,
  webSpeech: new (require('@/utils/webSpeech').default)(),
}
Vue.prototype.logout = function () {
  removeToken()
  $app.$store.commit('user/CLEAR')
  if ($app.$route.name !== LoginPage) {
    $app.$router.push({
      name: LoginPage,
      query: { redirect: $app.$route.path },
    })
  }
}

let userResolve
Vue.prototype.userReady = new Promise((resolve) => {
  userResolve = resolve
})

Vue.prototype.isLocalhost = location.hostname === 'localhost'

Vue.prototype.avatarHandler = function (avatar) {
  let src = ''
  if (avatar) {
    if (avatar.startsWith('/files/')) {
      src = `${$app.VUE_APP_BASE_API}/api` + avatar.replace('files', 'media')
    } else if (avatar.startsWith('/media/')) {
      src = `${$app.VUE_APP_BASE_API}/api` + avatar
    } else if (avatar.startsWith('//') || avatar.startsWith('http')) {
      src = avatar
    } else if (avatar.startsWith('http')) {
      src = avatar
    }
  }
  if (!src) {
    src = '/avatar.png'
  }
  return src
}

const defers = {
  maptalks: {
    promise: null,
    resolve: null,
  },
}

defers.maptalks.promise = new Promise((r) => {
  defers.maptalks.resolve = r
})

Vue.prototype.$defers = defers

Vue.prototype.userFetch = function () {
  Vue.prototype.$service
    .post(process.env.VUE_APP_API + '/account/selfInfo')
    .then((data) => {
      data.userId = data.id
      data.uid = data.id
      data.avatar = this.avatarHandler(data.avatar)
      this.$store.commit('user/SET_SELF', data)
      userResolve(data)
    })
    .catch((e) => {
      this.logout()
    })
}


window.addEventListener('load', () => {
  if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
    getToken().then(() => {
      setupApp()
    })
  } else {
    setupApp()
  }
})

function setupApp() {
  window.$app = new Vue({
    router,
    store,
    el: '#app',
    render: (h) => h(App),
    mixins: [require('./main.mixin').default],
  })
}


