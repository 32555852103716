window.bd2xh = function ([bd_lng, bd_lat]) {
  // 百度转火星坐标
  bd_lng = Number(bd_lng) || 0
  bd_lat = Number(bd_lat) || 0
  const x_pi = (Math.PI * 3000.0) / 180.0
  const x = bd_lng - 0.0065
  const y = bd_lat - 0.006
  let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
  let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
  return [z * Math.cos(theta), z * Math.sin(theta)]
}
window.wgs84togcj02 = function ([lng, lat]) {
  // wgs84转火星坐标
  lng = Number(lng) || 0
  lat = Number(lat) || 0
  // var x_PI = (3.14159265358979324 * 3000.0) / 180.0
  var PI = 3.1415926535897932384626
  var a = 6378245.0
  var ee = 0.00669342162296594323
  if (lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false) {
    return [lng, lat]
  } else {
    var dlat = ((lng, lat) => {
      var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
      ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
      ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
      ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
      return ret
    })(lng - 105.0, lat - 35.0)
    var dlng = ((lng, lat) => {
      var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
      ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
      ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
      ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
      return ret
    })(lng - 105.0, lat - 35.0)
    var radlat = (lat / 180.0) * PI
    var magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    var sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
    var mglat = lat + dlat
    var mglng = lng + dlng
    return [mglng, mglat]
  }
}
window.gcj02towgs84 = function ([lng, lat]) {
  // 火星坐标转wgs84
  lng = Number(lng) || 0
  lat = Number(lat) || 0
  // var x_PI = (3.14159265358979324 * 3000.0) / 180.0
  var PI = 3.1415926535897932384626
  var a = 6378245.0
  var ee = 0.00669342162296594323
  if (lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false) {
    return [lng, lat]
  } else {
    var dlat = ((lng, lat) => {
      var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
      ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
      ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
      ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
      return ret
    })(lng - 105.0, lat - 35.0)
    var dlng = ((lng, lat) => {
      var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
      ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
      ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
      ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
      return ret
    })(lng - 105.0, lat - 35.0)
    var radlat = (lat / 180.0) * PI
    var magic = Math.sin(radlat)
    magic = 1 - ee * magic * magic
    var sqrtmagic = Math.sqrt(magic)
    dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
    dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
    var mglat = dlat + lat
    var mglng = dlng + lng
    return [lng * 2 - mglng, lat * 2 - mglat]
  }
}
export default {
  state: {
    mapConfigs: {
      mapDashboard: {
        mapZoom: 5,
        mapCenter: window.gcj02towgs84([104.862151, 34.141206]),
        minZoom: 5,
        maxZoom: 24,
        maxClusterZoom: 17,
        noClusterWithOneMarker: false,
        pitch: 47,
        bearing: 0,
        dragable: false,
      },
      mapFence: {
        mapZoom: 11,
        mapCenter: window.gcj02towgs84([114.089675, 22.560574]),
        minZoom: 4,
        maxZoom: 24,
        maxClusterZoom: 17,
        noClusterWithOneMarker: false,
        pitch: 0,
        bearing: 0,
        dragable: false,
      },
      mapTrack: {
        mapZoom: 11,
        mapCenter: window.gcj02towgs84([114.089675, 22.560574]),
        minZoom: 4,
        maxZoom: 24,
        maxClusterZoom: 17,
        noClusterWithOneMarker: false,
        pitch: 0,
        bearing: 0,
        dragable: false,
      },
      mapCollection: {
        mapZoom: 11,
        mapCenter: window.gcj02towgs84([114.089675, 22.560574]),
        minZoom: 4,
        maxZoom: 26,
        maxClusterZoom: 17,
        noClusterWithOneMarker: true,
        pitch: 0,
        bearing: 0,
        dragable: false,
      },
      mapDevice: {
        mapZoom: 11,
        mapCenter: window.gcj02towgs84([114.089675, 22.560574]),
        minZoom: 4,
        maxZoom: 26,
        maxClusterZoom: 17,
        noClusterWithOneMarker: true,
        pitch: 0,
        bearing: 0,
        dragable: false,
      },
      mapXunjian: {
        mapZoom: 4.7,
        mapCenter: [104.10603286, 37.27380054],
        minZoom: 4,
        maxZoom: 26,
        maxClusterZoom: 17,
        noClusterWithOneMarker: true,
        pitch: 0,
        bearing: 0,
        dragable: true,
        centerCross: true,
      },
      mapScDigital: {
        mapZoom: 16,
        // mapCenter: [113.83486898, 22.60969313],
        mapCenter: [113.833305 - 0.000001 * 70, 22.60922063 - 0.00001 * 18],
        minZoom: 4,
        maxZoom: 26,
        maxClusterZoom: 17,
        noClusterWithOneMarker: true,
        pitch: 60,
        bearing: 0,
        dragable: true,
        centerCross: true,
      },
    },
    isOnline: true,
    xunjian0: {
      sheetMap: null,
      areaMap: null,
      areas: [],
    },
  },
  getters: {
    xunjian0SheetMapList(state) {
      return state.xunjian0.sheetMap ? Object.keys(state.xunjian0.sheetMap) : []
    },
  },
  actions: {
    clearMap({ commit, dispatch, getters, rootGetters, rootState, state }) {},
  },
}
