import { render, staticRenderFns } from "./ui-popover.vue?vue&type=template&id=0cfa2367&scoped=true"
import script from "./ui-popover.vue?vue&type=script&lang=js"
export * from "./ui-popover.vue?vue&type=script&lang=js"
import style0 from "./ui-popover.vue?vue&type=style&index=0&id=0cfa2367&lang=scss&scoped=true"
import style1 from "./ui-popover.vue?vue&type=style&index=1&id=0cfa2367&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_css-loader@6.11.0_webpack@5.92.1__lodash@4.17.21__mzqvytlaul3nppswafhhvjwwbu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cfa2367",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("F:\\workspace\\gmd-top-platform-web@pro3\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cfa2367')) {
      api.createRecord('0cfa2367', component.options)
    } else {
      api.reload('0cfa2367', component.options)
    }
    module.hot.accept("./ui-popover.vue?vue&type=template&id=0cfa2367&scoped=true", function () {
      api.rerender('0cfa2367', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/ui-popover.vue"
export default component.exports