const state = {
  lastUser: {},
  userInfo: {},
  map: {},
}

const mutations = {
  SET_SELF(state, self) {
    state.lastUser = self
    state.userInfo = self
    state.map[self.uid] = self
  },
  CLEAR(state) {
    state.userInfo = {}
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
