import axios from 'axios'
import { getToken } from '@/utils/auth'
import { stringify } from 'qs'

const timer = {}

let service

async function commonConfigs(url, config) {
  if (url.indexOf(process.env.VUE_APP_API + '/') === 0) {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }
    if (config.token === undefined) {
      let token = await getToken()
      if (!token) {
        if (timer.timer1) {
          clearTimeout(timer.timer1)
        }
        timer.timer1 = setTimeout(() => {
          $app.loginInvalid([1, url])
        }, 100)
        return false
      }
      config.headers.token = token
    } else {
      delete config.token
    }
  }
  return true
}

async function Get(url, config = {}) {
  let flag = await commonConfigs(url, config)
  if (!flag) {
    return Promise.resolve()
  }
  return service.get(url, config).then((res) => res.data || res)
}

async function Post(url, data, config = {}) {
  let flag = await commonConfigs(url, config)
  if (!flag) {
    return Promise.resolve()
  }
  // var isDeviceMapDeviceList = url == process.env.VUE_APP_API + '/deviceMap/deviceList'
  // if (isDeviceMapDeviceList) {
  //   var data = $app.$storage.getJson('api_v3_deviceMap_deviceList')
  //   if (Array.isArray(data) && data.length) {
  //     return Promise.resolve(data)
  //   }
  // }
  return service.post(url, data, config).then((res) => {
    var r = res.data || res
    // if (isDeviceMapDeviceList) {
    //   $app.$storage.setJson('api_v3_deviceMap_deviceList', r || [], 600)
    // }
    return r
  })
}

export default {
  install: function (Vue) {
    service = axios.create({
      baseURL: Vue.prototype.VUE_APP_BASE_API,
      timeout: 180000,
    })

    service.interceptors.request.use(
      (config) => {
        if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
          if (Object.prototype.toString.call(config.data) !== '[object String]')
            config.data = stringify(config.data)
        }
        return config
      },
      (error) => {
        console.debug(error)
        return Promise.reject(error)
      }
    )

    service.interceptors.response.use(
      (response) => {
        if (response.config.url.indexOf(process.env.VUE_APP_API + '/') === 0) {
          if (response.data.code !== 200) {
            let message = response.data.message

            return Promise.reject(message)
          } else {
            return response.data
          }
        }
        return response.data
      },
      (error) => {
        let code = error.response.status
        if (code === 432) { // token 过期
          if (sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
            return new Promise(resolve => {
              window.$loginPersistenceUtils.tokenClear().then(() => {
                resolve($app.$service[error.config.method](error.config.url, error.config.data))
              })
            })
          } else {
            $app.loginInvalid(2)
          }
        }

        return Promise.reject(error)
      }
    )

    Vue.prototype.$service = {
      post: Post,
      Post: Post,
      get: Get,
      Get: Get,
    }
  },
}
