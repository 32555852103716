export default {
  state: {
    map: null,
    mapPlugins: {
      Scale: null,
      ToolBar: null,
      MouseTool: null,
      PolygonEditor: null,
    },
    device: {},
    collection: {
      deviceMap: {},
      deviceList: [],
      dListShow: false,
      activeDevice: 0,
      setFitView: true,
      collection: [],
      collectionAll: [],
      collectionMap: {},
      collectionInfo: {},
      listShow: false,
    },
    mapLoaderShow: false,
  },
}
