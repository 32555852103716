let isSpeeding = true
let overspeed = 0.18
let _data = [
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:03.314', currentSpeed: 86, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.716319441774544', longitude: '113.85298872868493', overspeed: overspeed, roadLimitSpeed: 70, roadName: '广东省深圳市宝安区上南东路81号靠近黄埔深源工业城', roadType: '上南东路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'tpaH6kNyWpKE' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:04.302', currentSpeed: 83, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.716516137248966', longitude: '113.85300203924291', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区上南东路81号靠近黄埔深源工业城', roadType: '上南东路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'owZUojJRxdiR' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:05.313', currentSpeed: 82, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.7167095130299', longitude: '113.85300703759462', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区上南东路81号靠近黄埔深源工业城', roadType: '上南东路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: '6dJoKtMiJ3DB' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:06.327', currentSpeed: 82, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.716909548591833', longitude: '113.85301702354133', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'GCQ33V5iMMep' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:07.373', currentSpeed: 85, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.717111236824884', longitude: '113.85303532160525', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'aU1Q6yu61ul5' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:08.344', currentSpeed: 90, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.717319587747905', longitude: '113.85305694486776', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'u64m7qtCAmdV' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:09.307', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.71754128138209', longitude: '113.85307524409585', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'UM564UbO7dgK' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:10.304', currentSpeed: 95, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.717774639047814', longitude: '113.85309686881284', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'PhC8RdlYV7Nd' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:11.327', currentSpeed: 97, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.71800803439198', longitude: '113.8530968822599', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路88号靠近黄埔深源工业城', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'S6O6CQAObeWd' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:12.302', currentSpeed: 97, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.718248089548855', longitude: '113.85310188330834', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'RDSNP7zOdnk5' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:13.302', currentSpeed: 99, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.718478144993043', longitude: '113.85310522137497', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'xVSTI8mpyLMb' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:14.295', currentSpeed: 99, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.718708197595813', longitude: '113.85311022184773', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'JaKar02gtjIC' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:15.304', currentSpeed: 95, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.718933243130188', longitude: '113.85311854684508', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: '2Nb0DL6ISIUg' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:16.305', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.719148303405042', longitude: '113.85311689682939', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: '5u8N43Y7GnEI' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:17.305', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.719376689048005', longitude: '113.85312189720699', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区庄村路新2靠近黄埔深源工业城', roadType: '庄村路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'i2CXeeU3s2a5' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:18.306', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.71960007919167', longitude: '113.85312357248405', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'V2HoKQotHPh0' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:19.303', currentSpeed: 96, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.719818468045997', longitude: '113.85312524747306', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'KZGyY3VvXAJy' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:20.306', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.72005020542498', longitude: '113.8531202736052', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'lNb6aNnuCJ9n' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:21.307', currentSpeed: 96, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.72029028131796', longitude: '113.853113637812', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: '6g5pD8v5yz41' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:22.304', currentSpeed: 96, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.720522018804797', longitude: '113.85310866394524', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'nPFMygYGflT0' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:23.307', currentSpeed: 95, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.720750419222203', longitude: '113.85310535229259', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路393号靠近北方永发新二工业园', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'AkJhLx1faue5' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:24.305', currentSpeed: 94, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.72097382702841', longitude: '113.8530970531351', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路369号靠近深圳市恒利通混凝土有限公司(宝安分公司)', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'r89C3s1XHR6X' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:25.304', currentSpeed: 91, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.72119889621119', longitude: '113.85309207888565', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路369号靠近深圳市恒利通混凝土有限公司(宝安分公司)', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'KgQ01NNmpqKC' },
  { code: 214, data: { cityCode: '0755', createTime: '2023-10-19 10:07:26.308', currentSpeed: 89, deviceId: '36af595e7f7f410e9b43407dd92aa20b', isSpeeding: isSpeeding, latitude: '22.72140731455062', longitude: '113.85307546684021', overspeed: overspeed, roadLimitSpeed: 110, roadName: '广东省深圳市宝安区沙井东环路369号靠近深圳市恒利通混凝土有限公司(宝安分公司)', roadType: '沙井东环路', toUid: 62, type: 'RealTimePublish', uid: 72 }, method: 'PUSH', transaction: 'dPADY3NXaxCP' },
]
let count = 0


// import { uniq } from 'lodash'
import socket from '@/utils/socket.js'
import { removeToken, getToken } from '@/utils/auth'

import { moreUser } from '@/api/index'
import { lonlatSliceEnd, lonlatSliceLen } from './constant'

export default {
  computed: {},
  data() {
    return {}
  },
  beforeDestroy() {
    if (this.speedTestTimer) {
      clearInterval(this.speedTestTimer)
    }
  },
  async created() {
    if (await getToken()) {
      this.userFetch()
    }
    this.timeStrHandler()
    socket(this).then(($socket) => {
      window.$app.$socket = $socket
      $socket.add(this.messageHandler)
    })
    window.addEventListener('beforeunload', () => {
      $app.$socket.close()
      sessionStorage.clear()
      this.exitCall()
    })

    // count = 0
    // this.speedTestTimer = setInterval(() => {
    //   // if (this.$store.state.map.collection.activeDevice) {
    //   let data = JSON.parse(JSON.stringify(_data[count].data))
    //   data.uid = 72

    //   let [lng, lat] = window.gcj02towgs84([data.longitude, data.latitude])
    //   data.longitude = lng
    //   data.latitude = lat
    //   data.position = [lng, lat]
    //   data.key = lng + ',' + lat

    //   $app.$emit('push_RealTimePublish', data)
    //   count++
    //   if (count === _data.length) {
    //     count = 0
    //   }
    //   // }
    // }, 2000)
  },
  computed: {
    pool() {
      return this.$store.state.dashboard.notification.pool
    },
    room() {
      return this.$store.state.addressBook.call.room
    },
  },
  watch: {
    pool() {
      this.checkPool()
    },
  },
  data() {
    return {
      timeStr: '',
      timeStr2: '',
      date: new Date(),
      weekMap: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
    }
  },
  methods: {
    fetchSessionList() {
      let method = 'Session'
      let type = 'List'
      let data = {
        uid: this.userInfo.uid,
        page: 1,
      }
      $app.$socket.query({ method, type, data }).then((msg) => {
        if (Array.isArray(msg.data.sessions)) {
          this.$store.commit(
            'addressBook/SET_SESSIONS',
            msg.data.sessions
              .filter((e) => e.updateTime)
              .sort(function (a, b) {
                return new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime()
              })
          )
        }
      })
    },
    loadZLMRTCClient() {
      if (!window.ZLMRTCClient) {
        return import('ZLMRTCClient').then((ZLMRTCClient) => {
          return (window.ZLMRTCClient = ZLMRTCClient)
        })
      } else {
        return window.ZLMRTCClient
      }
    },
    timeStrHandler() {
      var dt = new Date()
      var y = dt.getFullYear()
      var mt = ('0' + (dt.getMonth() + 1)).slice(-2)
      var day = ('0' + dt.getDate()).slice(-2)
      var h = ('0' + dt.getHours()).slice(-2)
      var m = ('0' + dt.getMinutes()).slice(-2)
      var s = ('0' + dt.getSeconds()).slice(-2)
      this.timeStr = y + '/' + mt + '/' + day + `&nbsp;${this.weekMap[dt.getUTCDay()]}&nbsp;` + h + ':' + m + ':' + s
      this.timeStr2 = y + '/' + mt + '/' + day + ` ` + h + ':' + m + ':' + s
      this.date = dt
      setTimeout(() => {
        this.timeStrHandler()
      }, 100)
    },
    async messageHandler(msg) {
      let method = msg.method

      method === 'PUSH' && this.pushHandler(msg.data)
      method === 'SYSTEMCTL' && this.sysHandler(msg.data)
    },
    enableVideo(enable) {
      this.$store.state.addressBook.call.room.callMap[this.uid].video = enable

      this.$store.state.addressBook.call.room.localStream.getTracks()[1].enabled = enable

      let method = 'AVCall'
      let type = 'EnableVideo'

      let data = {
        avId: this.$store.state.addressBook.call.room.callMap[this.uid].avId,
        streamId: this.$store.state.addressBook.call.room.callMap[this.uid].streamId,
        uid: this.userInfo.uid,
        enable: enable,
      }
      $app.$socket.query({ method, type, data })
    },
    enableAudio(uid, enable) {
      if (uid != this.userInfo.uid) {
        return
      }

      let find = this.$copy(this.$store.state.addressBook.call.room.callMap[this.uid])
      find.audio = enable
      this.$store.state.addressBook.call.room.callMap = Object.assign({}, this.$store.state.addressBook.call.room.callMap, {
        [this.uid]: find,
      })

      this.$store.state.addressBook.call.room.localStream.getTracks()[0].enabled = enable

      let method = 'AVCall'
      let type = 'EnableAudio'

      let data = {
        avId: this.$store.state.addressBook.call.room.callMap[this.uid].avId,
        streamId: this.$store.state.addressBook.call.room.callMap[this.uid].streamId,
        uid: this.userInfo.uid,
        enable: enable,
      }
      $app.$socket.query({ method, type, data })
    },

    exitRoomHandler(data, _call) {
      let callList = []
      _call.room.callList.forEach((uid) => {
        if (uid != data.uid) {
          callList.push(uid)
        }
      })
      _call.room.callList = callList
      delete _call.room.callMap[data.uid]
      _call.room.callMap = Object.assign({}, _call.room.callMap)

      let count = 0
      Object.keys(_call.room.callMap).forEach((uid) => {
        if (!_call.room.callMap[uid].isReject) {
          count++
        }
      })
      if (count <= 1) {
        this.exitCall()
      }
    },
    async sysHandler(data) {
      switch (data.type) {
        case 'CPU':
          let system = data.system
          if (system) {
            if (system.cpu > 0.45) {
              this.addAlarm({
                content: 'cpu使用率过高，' + '使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: system.cpu > 0.7 ? '2' : '1',
              })
            } else {
              this.addAlarm({
                content: 'cpu使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: '0',
              })
            }

            if (system.mem > 0.55) {
              this.addAlarm({
                content: '内存使用率过高，使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: system.mem > 0.8 ? '3' : '1',
              })
            } else {
              this.addAlarm({
                content: '内存使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: '0',
              })
            }

            if (system.disks && system.disks.length) {
              let use = 0
              let free = 0
              let all = 0
              system.disks.forEach((item) => {
                use += item.use
                free += item.free
              })
              all = use + free
              let p = use / all
              if (p > 0.7) {
                this.addAlarm({
                  content: '硬盘容量不足，已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: p > 0.9 ? '3' : '1',
                })
              } else {
                this.addAlarm({
                  content: '硬盘已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: '0',
                })
              }
            }
          }

          break
        default:
          break
      }
    },
    async pushHandler(data) {
      let _call = this.$store.state.addressBook.call

      if (data.type === 'Calling2') {
        data.type = 'Calling'
      } else if (data.type === 'OffLine') {
        if (_call.room && _call.room.avId == data.avId && _call.room.callMap[data.uid]) {
          this.exitRoomHandler(data, _call)
        }
        return
      }
      switch (data.type) {
        case 'RealTimePublish':
          let [lng, lat] = window.gcj02towgs84([data.longitude, data.latitude])
          data.longitude = lng
          data.latitude = lat
          data.position = [lng, lat]
          data.key = lng + ',' + lat
          $app.$emit('push_RealTimePublish', data)
          break
        case 'Accepted':
          if (data.uid && data.streamId) {
            $app.$emit('live_call_accept', data)
          }
          break
        case 'BusyLine':
          if (data.uid == this.userInfo.uid) {
            removeToken()
            $app.MessageBox.alert('该账号已在别处登录，将自动登出', {
              confirmButtonText: '确定',
              type: 'warning',
              showClose: false,
              closeOnClickModal: false,
            })
              .then(() => {
                $app.logout()
              })
              .catch(() => {
                $app.logout()
              })
          }
          break
        case 'SYSTEMCTL':
          let system = data.system
          if (system) {
            if (system.cpu > 0.45) {
              this.addAlarm({
                content: 'cpu使用率过高，' + '使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: system.cpu > 0.7 ? '2' : '1',
              })
            } else {
              this.addAlarm({
                content: 'cpu使用率：' + (system.cpu * 100).toFixed(0) + '%',
                time: 'cpu_' + Date.now(),
                level: '0',
              })
            }

            if (system.mem > 0.55) {
              this.addAlarm({
                content: '内存使用率过高，使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: system.mem > 0.8 ? '3' : '1',
              })
            } else {
              this.addAlarm({
                content: '内存使用率：' + (system.mem * 100).toFixed(0) + '%',
                time: 'mem_' + Date.now(),
                level: '0',
              })
            }

            if (system.disks && system.disks.length) {
              let use = 0
              let free = 0
              let all = 0
              system.disks.forEach((item) => {
                use += item.use
                free += item.free
              })
              all = use + free
              let p = use / all
              if (p > 0.7) {
                this.addAlarm({
                  content: '硬盘容量不足，已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: p > 0.9 ? '3' : '1',
                })
              } else {
                this.addAlarm({
                  content: '硬盘已使用：' + (p * 100).toFixed(0) + '%',
                  time: 'disk_' + Date.now(),
                  level: '0',
                })
              }
            }
          }

          break
        case 'OnLine':
          this.$store.state.dashboard.onlineNum = data.onLine
          break
        case 'Text':
          let sessionId = this.$store.state.addressBook.itemInfo && this.$store.state.addressBook.itemInfo.id
          if (!sessionId) {
            return
          }
          if (data.sessionId == sessionId) {
            if (data.message.fromUser.uid !== this.userInfo.uid) {
              let find = this.$store.state.addressBook.chatList.find((e) => e.id === data.message.id)
              if (!find) {
                this.$store.state.addressBook.chatList.push({
                  avatar: data.message.fromUser.avatar,
                  createTime: data.message.createTime,
                  fromUid: data.message.fromUser.uid,
                  message: data.message.text,
                  name: data.message.fromUser.name,
                  sessionId: data.sessionId,
                  id: data.message.id,
                })
              }
            }
          } else if (!$app.$store.state.addressBook.sessions.find((e) => e.id == data.sessionId)) {
            $app.fetchSessionList()
          }
          break
        case 'Reject': // 有用户拒绝通话
          // {
          //   "type": "Reject",
          //   "avId": "12345678765432", # 创建通话时返回
          //   "uid": 16
          // }
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            find.streamId = ''
            find.isReject = true
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'EnableVideo':
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            if (!!find.video === !!data.enable) {
              return
            }
            find.video = !!data.enable
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'EnableAudio':
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            if (!!find.audio === !!data.enable) {
              return
            }
            find.audio = data.enable
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.uid]: find,
            })
          }
          break
        case 'Joined': // 有用户加入通话
          // "type": "Joined",
          // "avId": "12345678765432", # 创建通话时返回
          // "uid": 16,
          // "avatar": "",
          // "name": "admin",
          // "video": true,
          // "audio": true,
          // "streamId": "456796534567865_234u45678876543",
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.from.uid]

          if (!this.usersMap[data.from.uid]) {
            await $app.findUsers([data.from.uid])
          }
          if (find) {
            find.streamId = data.from.streamId
            find.isReject = false
          } else {
            _call.room.callList = [].concat(_call.room.callList, [data.from.uid])
            _call.room.callMap = Object.assign({}, _call.room.callMap, {
              [data.from.uid]: new _call.CallMember({
                type: 'Join',
                avId: data.avId,
                uid: data.from.uid,
                video: data.video,
                audio: data.audio,
                streamId: data.from.streamId,
              }),
            })
          }
          break
        case 'Exit': // 有用户退出通话
          if (!_call.room) {
            return
          }
          if (_call.room.avId != data.avId) {
            return
          }
          var find = _call.room.callMap[data.uid]
          if (find) {
            this.exitRoomHandler(data, _call)
          }
          break
        case 'Calling': // 被叫
          {
            // var data = {
            //   type: 'Calling',
            //   avId: '12345678765432',
            //   title: '标题',
            //   video: true,
            //   audio: true,
            //   isFuture: false,
            //   startTime: '开始时间（预约用）2023-01-01 00:00:00',
            //   endTime: '结束时间（预约用）2023-01-01 00:30:00',
            //   member: [15, 14, 16, 17, 18],
            //   fromUser: {
            //     uid: 15,
            //     avatar: '',
            //     name: 'admin',
            //     streamId: '456796534567865_234u45678876543',
            //   },
            //   createTime: '',
            // }
            let method = 'AVCall'
            let type = ''
            let avId = data.avId

            let title = data.avCall.title
            let video = data.avCall.video
            let audio = data.avCall.audio
            // let member = data.avCall.member
            let isFuture = false
            let startTime = ''
            let endTime = ''

            let uid = this.userInfo.uid
            let room = _call.room && _call.room.callMap[uid]
            let creatorUid = data.fromUser.uid
            if (uid == creatorUid) {
              return
            }

            if (room && room.avId == avId) {
              return
            }

            if (room && room.avId != avId) {
              let count = 0
              Object.keys(_call.room.callMap).forEach((uid) => {
                if (!_call.room.callMap[uid].isReject) {
                  count++
                }
              })
              if (count > 1) {
                type = 'Reject'
              } else {
                this.exitCall()
              }
            }

            if (!type) {
              let wait = 30000
              await new Promise((resolve) => {
                setTimeout(() => {
                  if (type) {
                    return
                  }
                  type = 'Reject'
                  $app.MessageBox.close()
                  resolve()
                }, wait)
                $app.MessageBox.confirm('确定接听吗?', '你被呼叫了', {
                  type: 'info',
                  showClose: false,
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                })
                  .then(() => {
                    type = 'Join'
                  })
                  .catch(() => {
                    type = 'Reject'
                  })
                  .finally(() => {
                    resolve()
                  })
              })
            }

            if (type === 'Join') {
              _call.room = new _call.CallRoom({
                avId: avId,
                title: title,
                video: video,
                audio: audio,
                isFuture: isFuture,
                startTime: startTime,
                endTime: endTime,
                type: 'Join',
              })
              this.$instance.chat.openDialog()
            } else if (type === 'Reject') {
              let data = {
                avId: avId,
                uid: uid,
              }
              $app.$socket.query({ method, type, data })
            }
          }
          break
        default:
          break
      }
    },
    checkPool() {
      if (this.pool.length) {
        if (this.$store.state.dashboard.notification.msgListAvailable) {
          this.$store.state.dashboard.notification.msgListAvailable = false
          setTimeout(() => {
            this.$store.state.dashboard.notification.msgListAvailable = true
            this.checkPool()
          }, 1000)
          let poolItem = this.pool.shift()
          this.$store.state.dashboard.notification.msgList.unshift(poolItem)
          this.$store.state.dashboard.notification.msgList.splice(1000)
          setTimeout(() => {
            this.$store.state.dashboard.notification.msgList.unshift({ id: 0 })
          }, 100)
        }
      }
    },
    addAlarm({ content, deviceId, time, level, audio }) {
      let find = this.$store.state.dashboard.notification.msgList.find((e) => e.id === time) || this.$store.state.dashboard.notification.pool.find((e) => e.id === time)
      if (find) {
        return
      }
      if (audio) {
        window.$app.$libs.webSpeech.speak(content)
      }
      let item = {
        id: time,
        time: this.$root.timeStr2,
        level: level ? level : '3',
        content: content,
        detail: '详情',
        deviceId: deviceId,
      }
      item.isRead = false
      this.$store.state.dashboard.notification.pool.push(item)
    },
    exitCall() {
      if (this.$store.state.addressBook.call.room && this.$store.state.addressBook.call.room.avId) {
        let method = 'AVCall'
        let type = 'Exit'
        let data = {
          uid: this.userInfo.uid,
          avId: this.$store.state.addressBook.call.room.avId,
        }
        $app.$socket.query({ method, type, data })
      }
      this.$store.state.addressBook.call.room = null
    },
    async toCallPre(userItem, isAudio) {
      let member = [this.userInfo.uid, userItem.id]
      let title = `${this.userInfo.name},${userItem.name}`
      let video = isAudio ? false : true
      let audio = isAudio ? true : false
      let isFuture = false
      let startTime = ''
      let endTime = ''
      let res = await this.call({ title, video, audio, isFuture, startTime, endTime, member })
      res && this.$instance.chat.openDialog()
      return res
    },
    toCall(userItem, isAudio) {
      return $app.MessageBox.confirm('确定呼叫' + userItem.name + '吗?', '提示', {
        type: 'info',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(async () => {
          return this.toCallPre(userItem, isAudio)
        })
        .catch(() => false)
    },
    async liveCall(option, silence = false) {
      let method = 'Live'
      let type = 'Invitation'
      let data = {
        uid: this.userInfo.uid,
        toUid: option.uid,
        video: true,
        audio: true,
      }

      return $app.$socket
        .query({ method, type, data })
        .then((msg) => {
          if (msg.data && !msg.data.online && msg.data.message && !silence) {
            $app.$message.error(msg.data.message)
          }
          return msg
        })
        .catch((msg) => {
          if (msg.data && msg.data.message && !silence) {
            $app.$message.error(msg.data.message)
          }
          return msg
        })
    },
    async call({ title, video, audio, isFuture, startTime, endTime, member }) {
      this.exitCall()
      let data = {
        uid: this.userInfo.uid,
        title: title,
        video: video,
        audio: audio,
        isFuture: isFuture || false,
        startTime: startTime || '',
        endTime: endTime || '',
        member: member,
      }

      let _ = {}
      let _call = this.$store.state.addressBook.call

      _.type = 'Calling'
      _.title = data.title
      _.video = data.video
      _.audio = data.audio
      _.isFuture = data.isFuture
      _.startTime = data.startTime
      _.endTime = data.endTime
      _.callList = this.$copy(data.member)
      _.callMap = {}
      _.callList.forEach((uid) => {
        let isCalling = uid == this.userInfo.uid
        _.callMap[uid] = new _call.CallMember({
          type: isCalling ? 'Calling' : 'Join',
          uid: uid,
          video: data.video,
          audio: data.audio,
        })
      })
      _call.room = new _call.CallRoom(_)
      return true
    },

    findUsers(users) {
      return moreUser({ uids: JSON.stringify(users) }).then((res) => {
        if (Array.isArray(res.data)) {
          res.data.forEach((e) => {
            e.userId = e.id
            e.uid = e.id
            e.avatar = this.avatarHandler(e.avatar)
            e.displayName = e.nickname || e.name
            this.usersMap[e.id] = e
          })
          this.$store.state.user.map = this.$copy(this.usersMap)
        }
      })
    },

    async lonlatHandler(e) {
      ['longitude', 'latitude'].forEach(key => {
        if (!e[key] || e[key] === 'null' || e[key] === '0.0') {
          e[key] = 0
          return
        }
        e[key] += ''
        const arr = e[key].split('.')
        if (Array.isArray(arr) && arr.length === 2) {
          arr[1] = (arr[1] + lonlatSliceEnd).slice(0, lonlatSliceLen)
          e[key] = arr.join('.') * 1
        } else {
          e[key] = 0
        }
      })
      let [lng, lat] = window.gcj02towgs84([e.longitude, e.latitude])
      if (lng <= 1 || lat <= 1 || lng > 180 || lat > 90) {
        lng = lat = 0
      }
      e.longitude = lng
      e.latitude = lat
      if (!e.coordinate) {
        var maptalks = await $app.$defers.maptalks.promise
        e.coordinate = new maptalks.Coordinate([lng, lat])
      }
      if (!e.position) {
        e.position = [lng, lat]
      }
      if (!e.key) {
        e.key = lng + ',' + lat
      }
      if (!e.address || e.address == 'null') {
        e.address = ''
      }
      if (e.uri) {
        let uriSplit = e.uri.split('/')
        e._fileName = uriSplit[uriSplit.length - 1]
      }
      return e
    },

    loadVideojs() {
      if (!window.videojs) {
        return import('video.js').then((videojs) => {
          import('video.js/dist/video-js.min.css')
          videojs.default.addLanguage('zh-CN', {
            Play: '播放',
            Pause: '暂停',
            'Current Time': '当前时间',
            Duration: '时长',
            'Remaining Time': '剩余时间',
            'Stream Type': '媒体流类型',
            LIVE: '直播',
            Loaded: '加载完成',
            Progress: '进度',
            Fullscreen: '全屏',
            'Exit Fullscreen': '退出全屏',
            'Picture-in-Picture': '画中画',
            'Exit Picture-in-Picture': '退出画中画',
            Mute: '静音',
            Unmute: '开启音效',
            'Playback Rate': '播放速度',
            Subtitles: '字幕',
            'subtitles off': '关闭字幕',
            Captions: '内嵌字幕',
            'captions off': '关闭内嵌字幕',
            Chapters: '节目段落',
            'Close Modal Dialog': '关闭弹窗',
            Descriptions: '描述',
            'descriptions off': '关闭描述',
            'Audio Track': '音轨',
            'You aborted the media playback': '视频播放被终止',
            'A network error caused the media download to fail part-way.': '网络错误导致视频下载中途失败。',
            'The media could not be loaded, either because the server or network failed or because the format is not supported.': '视频因格式不支持或者服务器或网络的问题无法加载。',
            'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': '由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。',
            'No compatible source was found for this media.': '无法找到此视频兼容的源。',
            'The media is encrypted and we do not have the keys to decrypt it.': '视频已加密，无法解密。',
            'Play Video': '播放视频',
            Close: '关闭',
            'Modal Window': '弹窗',
            'This is a modal window': '这是一个弹窗',
            'This modal can be closed by pressing the Escape key or activating the close button.': '可以按ESC按键或启用关闭按钮来关闭此弹窗。',
            ', opens captions settings dialog': ', 开启标题设置弹窗',
            ', opens subtitles settings dialog': ', 开启字幕设置弹窗',
            ', opens descriptions settings dialog': ', 开启描述设置弹窗',
            ', selected': ', 选择',
            'captions settings': '字幕设定',
            'Audio Player': '音频播放器',
            'Video Player': '视频播放器',
            Replay: '重新播放',
            'Progress Bar': '进度条',
            'Volume Level': '音量',
            'subtitles settings': '字幕设定',
            'descriptions settings': '描述设定',
            Text: '文字',
            White: '白',
            Black: '黑',
            Red: '红',
            Green: '绿',
            Blue: '蓝',
            Yellow: '黄',
            Magenta: '紫红',
            Cyan: '青',
            Background: '背景',
            Window: '窗口',
            Transparent: '透明',
            'Semi-Transparent': '半透明',
            Opaque: '不透明',
            'Font Size': '字体尺寸',
            'Text Edge Style': '字体边缘样式',
            None: '无',
            Raised: '浮雕',
            Depressed: '压低',
            Uniform: '均匀',
            Dropshadow: '下阴影',
            'Font Family': '字体库',
            'Proportional Sans-Serif': '比例无细体',
            'Monospace Sans-Serif': '单间隔无细体',
            'Proportional Serif': '比例细体',
            'Monospace Serif': '单间隔细体',
            Casual: '舒适',
            Script: '手写体',
            'Small Caps': '小型大写字体',
            Reset: '重置',
            'restore all settings to the default values': '恢复全部设定至预设值',
            Done: '完成',
            'Caption Settings Dialog': '字幕设定窗口',
            'Beginning of dialog window. Escape will cancel and close the window.': '打开对话窗口。Escape键将取消并关闭对话窗口',
            'End of dialog window.': '结束对话窗口',
            'Seek to live, currently behind live': '尝试直播，当前为延时播放',
            'Seek to live, currently playing live': '尝试直播，当前为实时播放',
            'progress bar timing: currentTime={1} duration={2}': '{1}/{2}',
            '{1} is loading.': '正在加载 {1}。',
            'No content': '无内容',
            Color: '颜色',
            Opacity: '不透明度',
            'Text Background': '文本背景',
            'Caption Area Background': '字幕区域背景',
            'Skip forward {1} seconds': '快进 {1} 秒',
            'Skip backward {1} seconds': '快退 {1} 秒',
          })
          return (window.videojs = videojs.default)
        })
      } else {
        return window.videojs
      }
    },
  },
}
